import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import DashboardPageDesktop from "nlib/pages/DashboardPage";
import React from "react";
import classNames from "classnames";

const DashboardPage = () => {
  return (
    <DashboardPageDesktop className={classNames(Css.dashboardPage, CommonCss.mobileContainer)} />
  );
};

export default React.memo(DashboardPage);
